import momemt from 'moment';

jQuery( document ).ready(function($) {

  // Featured Video
  const featuredVideo = document.querySelector('.featured-video')
  $('.video-item').click(function(e){
    const videoId = e.target.getAttribute('data-yt-id')
    featuredVideo.setAttribute('src', `https://www.youtube.com/embed/${videoId}`)
  })

  // music links
  $('.music-trigger').click(function(e){
    e.preventDefault()
    $('.music-links').removeClass('active')
    $(this).prev().find('.music-links').toggleClass('active')
  })

  // view all events
  $('.tour .btn').click(function(e){
    e.preventDefault()  
    $('#events').toggleClass('all')
  })

  // view full bio
  $('.bio .btn, .close').click(function(e){
    e.preventDefault()
    $('.popup-bio').toggleClass('active')
  })

  // Sliders
  $('.slider-videos').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><img src="./dist/img/prev.png" alt=""></button>',
    nextArrow: '<button type="button" class="slick-next"><img src="./dist/img/next.png" alt=""></button>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });

  $('.slider-music').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><img src="./dist/img/prev.png" alt=""></button>',
    nextArrow: '<button type="button" class="slick-next"><img src="./dist/img/next.png" alt=""></button>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });

  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Rachel%20wammack/events?app_id=45PRESS_RACHEL_WAMMACK',
		method: 'GET',
		dataType: 'json',
		error: () => {
			alert( 'Error fetching events!' );
		},
		success: data => {
			const events = $( '#events' );
			let html = '';
			if ( data.length ) {
				for ( let event of data ) {
					let location = event.venue.city + ', ' + event.venue.region;

					if ( location === ', ' ) {
						location = '';
					}

					if ( event.venue.name.toLowerCase() === 'streaming live' ) {
						location = 'Online';
					}

					html += '<div class="event">';
					html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
					html += '<div class="event-location">' + location + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-links">';
					for ( let offer of event.offers ) {
            if (offer.type === 'Tickets'){
              html += '<a href="' + offer.url + '" target="_blank" class="btn btn-red btn-small">' + offer.type + '</a>';
            }
					}
					html += '</div>';
					html += '</div>';
				}
				events.html( html );
			} else {
				events.html( 'No upcoming events.' );
        $('#tour .btn').hide()
			}
		}
	});

  // Instagram
  $.ajax( {
		url: 'https://www.juicer.io/api/feeds/RACHELWAMMACK',
		method: 'GET',
		dataType: 'json',
		success: data => {
			let html = '';
			let i = 0;
			for ( let item of data.posts.items ) {
				html += '<div>';
				html += '<a href="' + item.external_url + '" target="_blank">';
				html += '<img src="' + item.image + '" alt="' + item.id + '" />';
				html += '</a>';
				html += '</div>';

				i ++;
				if ( i >= 8 ) {
					break;
				}
			}

			$( '#instagram-posts' ).html( html );
		},
		error: () => {
			alert( 'Error fetching Instagram posts!' );
		}
	} );

  // Newsletter Form
  $("#rachel-wammack-website-newsletter-signup").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('.form-error').hide()
        $('.newsletter form').hide()
        $('.form-success').show()
      },
      error: function (err) {
        $('.form-error').show()
      }
    });
  });


  // mobile menu
  $('.menu-trigger, .menu-close').click(function(e){
    e.preventDefault()
    $('.nav').toggleClass('active')
  })
  
  $('.nav a').click(function(){
    $('.nav').removeClass('active')
  })

});